"use client"

import {TiArrowBack} from "@react-icons/all-files/ti/TiArrowBack";
import React from "react";
import {useTekstClient} from "hooks/useTekstClient";
import {useRouter} from 'next/navigation'

export const BackButton = ({backUrl}) => {


    const tekst = useTekstClient()
    const router = useRouter()

    const handleNavigate = () => {
        if (backUrl) {
            router.replace(backUrl)
        } else {
            router.back()
        }
    }

    return <section className={`flex justify-start content-center pb-3 cursor-pointer`} onClick={handleNavigate}>
        <TiArrowBack style={{color: "#23C1F4", fontSize: "25px"}}/>
        <div>{tekst('pagina:webshop:terug')}</div>
    </section>

}